<template>
    <div class="faq-container">
        <div class="banner">
            <h2> سوالات متداول </h2>
        </div>
        <div class="text-container">
            <div class="how-works">
                <h3> چگونگی محاسبه کارمزد </h3>
                <p>
                    کارمزد معاملات انجام شده در کندل، بر اساس سطح کاربری از سفارش گذار و سفارش پذیر در زمان معامله کسر میگردد. باید در نظر داشت که افزایش سطح کاربری با در نظر 
                    گرفتن افزایش میزان معاملات ارتقا میابد. هم چنین بررسی میزان معاملات و افزایش سطح کاربری در هر ۲۴ ساعت یک بار به صورت اتومات انجام و سطوح کاربری ارتقا میابد. 
                    بر همین اساس سطوح کاربری و کارمزد 
                </p>
            </div>
            <ShowInfoDropDown :data="messages"/>
        </div>
    </div>
</template>

<script>
import ShowInfoDropDown from '../PublicComponents/ShowInfoDropDown.vue'
    export default {
        name:'FAQ',
        components:{
            ShowInfoDropDown
        },
        data() {
            return {
                messages:[],
            }
        },
        methods:{
            async getFaq() {
                this.loading = true
                this.messages = await this.$axios.get('/faq')
                this.loading = false

            }
        },
        mounted() {
            this.getFaq();
        },
    }
</script>

<style lang="scss" scoped>
.text-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 72px;
    padding: 40px 120px;
}
.banner{
    background: url('../../assets/faqBanner.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    width: 100%;
    height: 450px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding: 100px 0;
    h2{
        font-weight: 700;
        font-size: clamp(28px,3vw,36px);
        color: var(--black);
    }
}
.faq-container{
    display: flex;
    flex-direction: column;
    background: var(--homepage-bg);
    row-gap: 72px;
}
.how-works{
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    h3{
        font-weight: 700;
        font-size: clamp(24px,3vw,28px);
        color: var(--darker-primary);
    }
}
@media only screen and(max-width:1100px){
    .text-container{
        padding: 20px;
    }
    .banner{
        background: unset !important;
        height: 250px !important;
    }
}
</style>