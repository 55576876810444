<template>
    <div class="container">
        <div :class="{'selected-row':selectedItem==item.id}" @click="showItems(item.id)" v-for="(item,index) in data" :key="index" class="item">
            <div class="row1">
                <span class="title"> {{item.question}} </span>
                <div class="more-info">
                    <span v-if="item.createdAtDateTime" class="date"> {{$G2J(item.createdAtDateTime)}} </span>
                    <inline-svg :class="{'rotate':selectedItem==item.id}" class="arrow" :src="require('../../assets/Icons/arrow.svg')" />
                </div>
            </div>
            <transition name="dropdown" >
                <p v-if="selectedItem==item.id" class="row2"> {{item.answer}} </p>
            </transition>
        </div>
    </div>
</template>

<script>
    export default {
        name:'ShowInfoDropDown',
        props:['data'],
        data() {
            return {
                selectedItem:null,
                test:[
                    {
                        answer: "شما می توانید رمزارزهای خود را به مدت طولانی در کیف پول های گرم که همان نرم افزارهای آنلاین هستند و کیف پول های سرد یا همان سخت افزارهای نگهداری رمزارز می باشند، نگهداری کنید. قائدتا هر کدام از آنها معایب و مزایایی دارند.",
                        id: 1,
                        question: "رمزارزها یا همان ارزهای دیجیتال را چگونه نگهداری کنیم؟",
                        createdAtDateTime: "2022-06-01T15:09:47"
                    },
                    {
                        answer: "شما می توانید رمزارزهای خود را به مدت طولانی در کیف پول های گرم که همان نرم افزارهای آنلاین هستند و کیف پول های سرد یا همان سخت افزارهای نگهداری رمزارز می باشند، نگهداری کنید. قائدتا هر کدام از آنها معایب و مزایایی دارند.",
                        id: 2,
                        question: "رمزارزها یا همان ارزهای دیجیتال را چگونه نگهداری کنیم؟",
                        createdAtDateTime: "2022-06-01T15:09:47"
                    }
                ]
            }
        },
        methods:{
                showItems(id){
                    if(this.selectedItem==id){
                        this.selectedItem=null
                    }else{
                        this.selectedItem=id
                    }
                }
        },
        created:function (){
            window.addEventListener("click",event => {
                if(!event.target.closest('.item')){
                    this.selectedItem=null
                }
            })
        }
    }
</script>

<style lang="scss" scoped>
.selected-row{
    background: var(--primary) !important;
    color: var(--homepage-bg) !important;
    span{
        color: var(--homepage-bg) !important;
    }
    .arrow{
        fill: var(--homepage-bg) !important;
    }
}
.row1{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .title{
        color: var(--black);
        font-weight: 500;
        font-size: clamp(14px,2vw,16px);
    }
    .more-info{
        display: flex;
        flex-direction: row;
        column-gap: 12px;
        align-items: center;
        .date{
            color: var(--a-default);
            font-weight: 300;
            font-size: clamp(14px,2vw,16px);
        }
        .arrow{
            fill: var(--primary);
        }
    }
}
.row2{
    width: 95%;
    font-weight: 400;
    font-size: clamp(14px,2vw,16px);
}
.item{
    overflow: hidden;
    transition: 600ms;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    padding: 15px 24px;
    background: #F0F0F5;
    box-shadow: 0px 0px 4px -1px rgba(65, 70, 192, 0.1);
    border-radius: 8px;
    &:hover{
        box-shadow: 0px 0px 8px -1px rgba(65, 70, 192, 0.2);
    }
}
.container{
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    z-index: 3;
}
</style>